.collection-container {
    display: flex;
    justify-content: center;
    padding: 16px;
    background-color: #f3f4f6; /* Light gray background */
  }
  
  .collection-wrapper {
    display: flex;
    gap: 16px;
    overflow-x: auto; /* Enables horizontal scrolling */
    scroll-behavior: smooth;
  }
  
  .card {
    background-color: white;
    padding: 16px;
    border: 1px solid #d1d5db; /* Light gray border */
    min-width: 300px;
    max-width: 350px; /* Keeps the card width the same as before */
    flex: 0 0 auto; /* Keeps the cards inline and allows horizontal scrolling */
  }
  
  .card-title {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  
  .card-item img {
    width: 100%;
    height: auto;
  }
  
  .card-item p {
    margin-top: 8px;
  }
  
  .card-link {
    color: #2563eb; /* Blue link */
    margin-top: 8px;
    display: block;
  }
  
  /* Add some padding for smooth scrolling on mobile */
  .collection-wrapper::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  
  @media (max-width: 768px) {
    .collection-wrapper {
      padding-bottom: 16px; /* Add space for touch scrolling */
    }
  }
  