/* General styling for all screen sizes */
.video-gallery {
  padding: 20px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-color: transparent; /* No background color */
  border-radius: 0; /* Remove rounded corners */
  box-shadow: none; /* Remove shadow */
}


h2 {
  margin-bottom: 20px;
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
}

/* Horizontal scroll container */
.video-scroll-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  gap: 20px; /* Gap between video items */
  justify-content: flex-start; /* Align items to the left */
  scroll-behavior: smooth; /* Smooth scrolling */
}

/* Hide scrollbar */
.video-scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for webkit browsers */
}

.video-scroll-container {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

/* Video items inside the horizontal scroll */
.video-item {
  position: relative;
  flex: 0 0 auto;
  width: 260px;
  height: 420px;
  border-radius: 0px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background-color: transparent; /* No background color for video items */
}

.video-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

.shop-now-btn {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1.1em;
  transition: background-color 0.3s, transform 0.3s;
}

.shop-now-btn:hover {
  background-color: rgba(33, 128, 130, 0.7);
 /* Slight scale effect on hover */
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .video-item {
      width: 220px;
      height: 300px;
  }

  h2 {
      font-size: 2.2em;
  }
}

@media screen and (max-width: 768px) {
  .video-item {
      width: 180px;
      height: 240px;
  }

  h2 {
      font-size: 1.8em;
  }

  .shop-now-btn {
      padding: 10px 18px;
      font-size: 1em;
  }

  .video-scroll-container {
      gap: 15px;
  }
}

@media screen and (max-width: 576px) {
  .video-item {
      width: 150px;
      height: 240px;
  }

  h2 {
      font-size: 1.5em;
  }

  .shop-now-btn {
      padding: 8px 16px;
      font-size: 0.9em;
  }
}





.shop-the-reel-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #1e1e1e;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.shop-the-reel-heading span {
  color: #ff477e; /* Highlight with GenZ pink */
  font-style: italic;
  text-shadow: 0 4px 10px rgba(255, 71, 126, 0.6);
}

.shop-the-reel-heading::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #ff477e, #4facfe);
  border-radius: 2px;
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
