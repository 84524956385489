  .snowfall {
    position: fixed; /* Make the snowfall fixed on the screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevents snowflakes from blocking page interactions */
    z-index: 9999; /* Ensure it stays on top of other content */
  }
  
  .snowflake {
    position: absolute;
    top: -10px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.8;
    animation: fall linear infinite;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(-10px);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh);
      opacity: 0.6;
    }
  }
  