
.ads-carousel {
    margin: 0; /* Remove all margin */
    border: none;
    width: 100%; /* Set the width to 100% to cover the full width of the page */
    max-width: none; /* Remove any max-width restriction */
}

.ad-item {
    text-align: center;
    border:none;
}

.ad-image {
    width: 100%; /* Ensure the video covers the full width of the container */
    height: 456px; /* Set the height to 420px */
    object-fit: fill; /* Ensure the video covers the container */
    margin: 0; /* Remove any margin */
}

@media (max-width: 768px) {
    .ad-image {
        height: 420px; /* Maintain height for tablets */
    }
}

@media (max-width: 480px) {
    .ad-image {
        max-height: 206px;
    }
} 

.ad-link {
    display: block;
    text-decoration: none;
}

.ad-link:hover {
    opacity: 0.9;
}
