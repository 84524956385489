/* 
@media (min-width: 1024px) {
  .carousel-image-wrapper {
    width: 100%; 
    height: 100%; 
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


@media (min-width: 768px) and (max-width: 1023px) {
  .carousel-image-wrapper {
    width: 100%; 
    height: 100%;
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 767px) {
  .carousel-image-wrapper {
    width: 100%; 
    height: 100%;
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease, opacity 0.3s ease;
}

.thumbnail.active {
  border-color: #000;
}

.thumbnail.inactive {
  opacity: 0.5;
}

.carousel-image-wrapper {
  cursor: zoom-in;
  overflow: hidden;
}

.zoom-1 .carousel-image {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.zoom-2 .carousel-image {
  transform: scale(2); 
  transition: transform 0.3s ease-in-out;
}

.zoom-2 {
  pointer-events: none;
} */


/* Desktop: Larger screens */
@media (min-width: 1024px) {
    .carousel-image-wrapper {
      width: 100%; 
      height: 100%; 
      object-fit: contain;
    }
  
    .carousel-image {
      width: 100%;
      height: 100%;
      object-fit: contain; /* Ensure the entire image is visible */
    }
  }
  
  /* Tablet: Medium screens */
  @media (min-width: 768px) and (max-width: 1023px) {
    .carousel-image-wrapper {
      width: 100%; 
      height: 100%; /* Smaller height for medium screens */
    }
  
    .carousel-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
  /* Mobile: Smaller screens */
  @media (max-width: 767px) {
    .carousel-image-wrapper {
      width: 100%; 
      height: 100%; /* Limit height on smaller screens */
    }
  
    .carousel-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
  .thumbnail {
    width: 50px; /* Smaller size for thumbnails */
    height: 50px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease, opacity 0.3s ease;
  }
  
  .thumbnail.active {
    border-color: #000;
  }
  
  .thumbnail.inactive {
    opacity: 0.5;
  }
  
  .carousel-image-wrapper {
    cursor: zoom-in;
    overflow: hidden;
  }
  
  .zoom-1 .carousel-image {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  
  .zoom-2 .carousel-image {
    transform: scale(2); 
    transition: transform 0.3s ease-in-out;
  }
  
  .zoom-2 {
    pointer-events: none;
  }
  