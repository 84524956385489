/* TrendingProducts.css */
.trending-products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Increased container size */
    gap: 30px;
    padding: 40px;
    justify-content: center;
    background-color: #f2f4f7; /* Light, modern background color */
}

.product-item {
    background-color: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Strong shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
    position: relative;
    cursor: pointer;
}

.product-item:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.product-image {
    width: 100%;
    height: 250px; /* Larger product image */
    object-fit: cover;
    border-bottom: 2px solid #eceff1;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.product-item:hover .product-image {
    transform: scale(1.05);
}

.product-name {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 700;
    color: #2b2e4a; /* Modern dark color */
    letter-spacing: 0.5px;
    font-family: 'Poppins', sans-serif; /* Trendy modern font */
}

.product-price {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    background-color: #ffdd57; /* Bright yellow background for attention */
    color: #2c3e50;
    padding: 10px 18px;
    border-radius: 25px;
    display: inline-block;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.icon-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.icon-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.icon-button svg {
    fill: white;
}

.quick-view {
    font-size: 14px;
    color: #7f8c8d;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .trending-products-container {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Responsive grid */
        gap: 20px;
    }

    .product-item {
        padding: 15px;
    }

    .product-image {
        height: 200px;
    }
}
