/* Custom CSS */
* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: #f4f4f4; /* Light background for contrast */
}

.flex-container {
    display: flex;
    justify-content: flex-start; /* Align children to the left */
    align-items: stretch; /* Ensure all items have the same height */
    padding: 16px; /* Add some padding around the component */
    max-width: 100%; /* Allow full width */
    margin: 0; /* Remove margin to ensure left alignment */
}

.products {
    flex: 0 0 40%; /* Smaller width for the product container */
    max-width: 40%; /* Adjust this as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align items */
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for product cards */
    gap: 16px; /* Space between cards */
}

.product-card {
    background-color: white; /* White background for product cards */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensure equal height for cards */
    padding: 8px; /* Smaller padding inside cards */
}

.product-card:hover {
    transform: scale(1.05);
}

.product-card img {
    width: 100%; /* Smaller width for product images */
    height: 100%; /* Smaller height for product images */
    object-fit: cover; /* Ensure images cover the area without distortion */
    transition: opacity 0.3s ease;
    margin: 0 auto; /* Center align images */
}

.product-card img:hover {
    opacity: 0.9;
}

.advertisement {
    flex: 1; /* Allow advertisement to take up remaining space */
    height: auto; /* Auto height to fill the space */
    position: relative;
    background-color: #eaeaea; /* Light gray background for ad */
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Ensure image doesn't overflow */
    margin-left: 20px; /* Add space between product and ad */
}

.advertisement img {
    width: 100%; /* Ensure the image takes full width */
    height: 100%; /* Fill the height of the container */
    object-fit: cover; /* Maintain aspect ratio */
}

.advert-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.explore-button {
    padding: 10px 20px;
    background-color: #f0b429; /* Peach color */
    color: white; /* White text for contrast */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.explore-button:hover {
    background-color: #d99e23; /* Darker peach on hover */
}

@media (max-width: 768px) {
    /* Hide the component on small screens */
    .flex-container {
        display: none;
    }
}
