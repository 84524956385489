/* ScrollingBanner.css */

.scrolling-banner {
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  background-color: #ffffff; /* Dark background for a professional look */
  color: #24221d; /* Gold text for contrast */
  font-weight: 600; /* Increased weight for emphasis */
  font-size: 1.2rem; /* Adjusted font size for better visibility */
  text-align: center; /* Center text horizontally */
  position: relative;
  overflow: hidden;
  height: 35px; /* Fixed height */
  width: 100%; /* Full width of the container */
  margin: 0; /* Removed margin */
  padding: 0; /* Removed padding */
  padding-top: 2px;
  /* Optional: Added shadow for depth */
  box-sizing: border-box; /* Helps manage padding and borders */
}

.scrolling-banner::before,
.scrolling-banner::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  /* Matching background color */
  z-index: 1;
}

.scrolling-banner::before {
  left: 0;
}

.scrolling-banner::after {
  right: 0;
  transform: rotate(180deg);
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  animation: scroll 10s linear infinite;
  line-height: 35px; /* Match line-height to the banner height for vertical centering */
  font-size: 1.2rem; /* Set font size for scrolling text */
 /* More subtle shadow for better contrast */
}

@keyframes scroll {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  100% {
    transform: translateX(-100%); /* Move off-screen to the left */
  }
}

/* Responsive font size adjustments */
@media (max-width: 768px) {
  .scrolling-banner {
    font-size: 1rem; /* Adjusted font size for tablets */
  }

  .scrolling-text {
    font-size: 1rem; /* Adjusted font size for text on tablets */
  }
}

@media (max-width: 480px) {
  .scrolling-banner {
    font-size: 0.9rem; /* Smaller font size for mobile */
  }

  .scrolling-text {
    font-size: 0.9rem; /* Adjusted font size for text on mobile */
  }
}
