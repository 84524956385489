/* UserSubCategory.css */
/* Additional custom styles can be added here if needed */
/* .category-item {
    transition: transform 0.3s ease;
}

.category-item:hover {
    transform: scale(1.05);
} */

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

/* Ensure all cards are of the same size */
.subcategory-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 250px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.subcategory-item:hover {
  transform: scale(1.05);
}

/* Ensure image size is consistent and covers the space */
.subcategory-image {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Ensures image covers the box while maintaining aspect ratio */
  border-bottom: 1px solid #e0e0e0;
}

/* Content section of the card */
.subcategory-content {
  padding: 16px;
  text-align: left;
}

/* Subcategory name styling */
.subcategory-name {
  font-weight: bold;
  font-size: 16px;
}

/* Responsive tweaks for smaller screens */
@media (max-width: 768px) {
  .subcategory-item {
    max-width: 100%;
  }

  .subcategory-image {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .subcategory-image {
    height: 320px;
  }

  .subcategory-name {
    font-size: 14px;
  }
}
