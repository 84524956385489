/* CategoryCarousel.css */
.categories-carousel {
    margin: 20px;
    background-color: rgb(233, 232, 232);
    display: flexbox;
    height: 220px;
    padding: 56px 0;
    margin: 0;

}

.category-item {
    text-align: center;
    
}

.category-image-wrapper {
    width: 100px; /* Default size */
    height: 100px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.category-name {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

/* Slick Carousel Arrows */
.slick-prev:before,
.slick-next:before {
    color: black; /* Change arrow color */
    font-size: 20px; /* Adjust arrow size */
}

/* Customize dots */
.slick-dots li button:before {
    color: gray; /* Change dot color */
}

.slick-dots li.slick-active button:before {
    color: black; /* Change active dot color */
}

/* Responsive styles for screens up to 768px */
@media screen and (max-width: 768px) {
    .category-image-wrapper {
        width: 88px; /* Decrease size */
        height: 88px;
    }

    .category-name {
        font-size: 12px; /* Adjust font size */
    }
}

/* Responsive styles for screens up to 400px */
@media screen and (max-width: 400px) {
    .category-image-wrapper {
        width: 88px; /* Further decrease size */
        height: 88px;
    }

    .category-name {
        font-size: 10px; /* Further adjust font size */
    }
}
