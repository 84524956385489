/* UserDrawer.css */

/* General styles for the drawer */
.modern-drawer {
  background-color: #f9f9f9; /* Light grey background */
  color: #333333; /* Dark text for contrast */
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #ddd; /* Subtle border for definition */
}

/* Scrollbar customization */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b2a587; /* Beige scrollbar for subtle contrast */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Light grey track */
}

/* Modern logo style */
.modern-logo {
  display: block;
  margin: 0 auto 15px auto;
  width: 140px;
  opacity: 0.95;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modern-logo:hover {
  opacity: 1;
  transform: scale(1.02);
}

/* List and list items */
.modern-list {
  padding: 0;
}

.modern-list .MuiListItem-root {
  padding: 8px 15px;
  margin-bottom: 6px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.modern-list .MuiListItem-root:hover {
  background-color: #e6e6e6; /* Light hover effect */
}

/* Icon styles */
.modern-icon {
  color: #666666; /* Medium grey for subtle contrast */
  margin-right: 12px;
  transition: color 0.2s ease;
}

.modern-list .MuiListItem-root:hover .modern-icon {
  color: #333333; /* Darker on hover */
}

/* Text styles */
.MuiListItemText-root {
  .MuiTypography-root {
    font-weight: 500;
    color: #333333; /* Dark text for readability */
    font-size: 0.95rem; /* Adjusted for a balanced look */
  }
}

/* Divider styling */
.custom-divider {
  background-color: #ddd; /* Light grey */
  margin: 10px 0;
}

/* Enhanced hover effect */
.modern-list .MuiListItem-root:hover {
  background-color: #eaeaea;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

/* Collapsible list transition */
.expandable-item .MuiCollapse-wrapperInner {
  animation: fadeIn 0.2s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scaleY(0.98);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

/* Header and section styling */
.header-section {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333333;
}

/* Hierarchical hover effects */
.modern-list .MuiListItem-root.primary:hover {
  border-left: 3px solid #b2a587; /* Accent indicator */
}

.modern-list .MuiListItem-root.secondary:hover {
  background-color: #f3f3f3; /* Slightly darker for secondary */
}
