.image-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.image-container {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  gap: 20px;
  padding: 20px;
  justify-content: center; /* Center images */
}

.image-box {
  width: 475px;
  height: 485px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.image-box:hover {
  transform: scale(1.05);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.nav-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
  padding: 10px;
}

.nav-button:disabled {
  color: #ccc;
  cursor: default;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
  .image-box {
    width: 390.4px;
    height: 390.4px;
  }
}

@media screen and (max-width: 768px) {
  .image-box {
    width: 475px;
    height: 485px;
  }
}

@media screen and (max-width: 480px) {
  .image-box {
    width: 425px;
    height: 425px;
    margin: auto;
    border-radius: 15px; /* Rounded corners on mobile */
  }

  .shop-now-button {
    left: 10px; /* Shift button slightly to the right */
    bottom: 20px; /* Adjust bottom spacing for better positioning */
  }
}

@media screen and (max-width: 320px) {
  .image-box {
    width: 280px;
    height: 320px;
  }

  .shop-now-button {
    left: 10px; /* Further adjust for very small screens */
    bottom: 15px;
    font-size: 15px; /* Slightly smaller text for the button */
    padding: 6px 15px; /* Adjust padding for smaller buttons */
  }
}


.image-box {
  position: relative;
}

.shop-now-button {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background: linear-gradient(135deg, #f1c841, #f4b3009d);
  color: #ffffff;
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
  padding: 8px 18px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  border: 2px solid #fff;
}

.shop-now-button:hover {
  background: linear-gradient(135deg, #f4b400, #f7d976);
  transform: scale(1.065);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  color: #333;
}

.shop-now-button:active {
  transform: scale(0.95);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
